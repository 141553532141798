import React from 'react';
import type PostListMasonry from '../../../feed-page/components/post-list/post-list-masonry';
import type PostListMobile from '../../../feed-page/components/post-list/post-list-mobile';
import type PostListSimple from '../../../feed-page/components/post-list/post-list-simple';
import type PostListItem from '../../../feed-page/components/post-list-item';
import type SideBySide from '../../../feed-page/components/post-list-item/mobile/side-by-side';
import type TextOnImage from '../../../feed-page/components/post-list-item/mobile/text-on-image';
import type { Post } from '../post/post';
import type PostListProGallery from '../post-list-pro-gallery';

export type ComponentsContextValue = {
  PostListProGallery: typeof PostListProGallery;
  PostListSimple: typeof PostListSimple;
  PostListMasonry: typeof PostListMasonry;
  PostListMobile: typeof PostListMobile;
  PostListItem: typeof PostListItem;
  Post: typeof Post;
  SideBySideMobile: typeof SideBySide;
  TextOnImageMobile: typeof TextOnImage;
};

export type ComponentKey = keyof ComponentsContextValue;

const Empty: React.FC = () => null;

export const ComponentsContext = React.createContext<ComponentsContextValue>({
  PostListProGallery: Empty as any,
  PostListSimple: Empty as any,
  PostListMasonry: Empty as any,
  PostListMobile: Empty as any,
  PostListItem: Empty as any,
  Post: Empty as any,
  SideBySideMobile: Empty as any,
  TextOnImageMobile: Empty as any,
});
